/* ENVIRONMENT VARIABLES */

export const APP_ENVIRONMENT = process.env.REACT_APP_ENV
export const API_BASE_URL = process.env.REACT_APP_BASE_API_URL
export const NODE_ENVIRONMENT = process.env.NODE_ENV

/* BUSINESS */

export const USER_ROLES = {
  ADMIN: 'admin',
  COMPANY: 'company',
  RESOLVER: 'resolver',
  EVALUATOR: 'evaluator'
}

export const USER_FEATURES = {
  CHALLENGE: 'challenge',
  EVALUATION: 'evaluation',
  IDEA: 'idea'
}

export const USER_TERMS_TYPE = {
  USER: 'user',
  COMPANY: 'company',
  RESOLVER: 'resolver',
  EVALUATOR: 'evaluator'
}

export const SOLUTION_STATUS = {
  APPROVED: 'Approved',
  DISAPPROVED: 'Archived',
  CREATED: 'Created',
  LAUNCHED: 'Launched',
  CANCELED: 'Canceled'
}

export const EVALUATOR_INVITE_STATUS = {
  INVITED: 'invited',
  ACCEPTED: 'accepted',
  REFUSED: 'rejected',
  CANCELED: 'canceled'
}

export const COMPANY_EVALUATION_INVITE_STATUS = {
  ACCEPTED: 'accepted',
  INVITED: 'invited',
  ERROR: 'error',
  REFUSED: 'rejected',
  EXPIRED: 'expired',
  CANCELED: 'canceled'
}

export const EVALUATION_STATUS = {
  CREATED: 'created',
  PENDING: 'pending',
  EVALUATED: 'evaluated',
  NOT_ACCEPTED: 'not accepted',
  CANCELED: 'canceled'
}

/* ROUTE DOMAIN */

export const ROUTES = {
  NOT_FOUND: '*',
  HOTSITE: {
    ROOT: '/',
    MARKETPLACE: '/marketplace',
    REGISTER: {
      EVALUATOR: '/register/evaluator/:challengeId',
      RESOLVER: '/register/resolver'
    },
    CONTACT: '/contact',
    CHALLENGE: '/challenge/:challengeId',
    LANDINGPAGE: '/mapadainovacao'
  },
  USER_AUTH: {
    LOGIN: '/login',
    FORGOT_PASSWORD: '/forgot-password',
    RESET_PASSWORD: '/user/reset/:userId/:token'
  },
  HUB: {
    ROOT: '/hub',
    DASHBOARD: '/dashboard',
    USER_CONFIG: '/user-config',
    COMPANY: {
      CHALLENGE_LIST: '/company/challenge',
      CALENDAR: '/company/calendar',
      CHALLENGE_TEMPLATE: '/company/challenge/template',
      NEW_CHALLENGE: '/company/challenge/template/new',
      EDIT_CHALLENGE: '/company/challenge/:challengeId/edit',
      GET_CHALLENGE: '/company/challenge/:challengeId',
      CHALLENGE_EVALUATORS: '/company/challenge/:challengeId/evaluators',
      CHALLENGE_SOLUTIONS: '/company/challenge/:challengeId/solutions'
    },
    STARTUP: {
      CHALLENGE_LIST: '/startup/challenge',
      GET_CHALLENGE: '/startup/challenge/:challengeId',
      APPLY_CHALLENGE: '/startup/challenge/:challengeId/apply',
      IDEA_LIST: '/startup/idea',
      GET_IDEA: '/startup/idea/:ideaId',
      EDIT_IDEA: '/startup/idea/:ideaId/challenge/:challengeId'
    },
    EVALUATOR: {
      CHALLENGE_LIST: '/evaluator/challenge',
      GET_CHALLENGE: '/evaluator/challenge/:challengeId',
      EVALUATE_IDEA: '/evaluator/challenge/:challengeId/step/:stepId/idea/:ideaId',
      INVITES: '/evaluator/invites'
    }
  }
}

/* INPUT MASKS */

export const INPUT_MASK_FORMAT = {
  CEP: '00000-000',
  PHONE: '(00) 0000-0000',
  MOBILE: '(00) 00000-0000',
  CPF: '000.000.000-00'
}

/* DROPDOWN */

export const DROPDOWN_BRAZIL_STATES = [
  { value: '', label: 'Selecione' },
  { value: 'AC', label: 'AC' },
  { value: 'AP', label: 'AP' },
  { value: 'AL', label: 'AL' },
  { value: 'AM', label: 'AM' },
  { value: 'BA', label: 'BA' },
  { value: 'CE', label: 'CE' },
  { value: 'DF', label: 'DF' },
  { value: 'ES', label: 'ES' },
  { value: 'GO', label: 'GO' },
  { value: 'MA', label: 'MA' },
  { value: 'MT', label: 'MT' },
  { value: 'MS', label: 'MS' },
  { value: 'MG', label: 'MG' },
  { value: 'PA', label: 'PA' },
  { value: 'PB', label: 'PB' },
  { value: 'PR', label: 'PR' },
  { value: 'PE', label: 'PE' },
  { value: 'PI', label: 'PI' },
  { value: 'RJ', label: 'RJ' },
  { value: 'RN', label: 'RN' },
  { value: 'RS', label: 'RS' },
  { value: 'RO', label: 'RO' },
  { value: 'RR', label: 'RR' },
  { value: 'SC', label: 'SC' },
  { value: 'SP', label: 'SP' },
  { value: 'SE', label: 'SE' },
  { value: 'TO', label: 'TO' }
]
export const RESOLVER_DROPDOWN_ROLES = [
  { value: '', label: 'Selecione' },
  { value: 'Sócio(a)/Fundador(a)', label: 'Sócio(a)/Fundador(a)' },
  { value: 'Presidente/Vice-Presidente', label: 'Presidente/Vice-Presidente' },
  { value: 'Diretor(a)', label: 'Diretor(a)' },
  { value: 'Coordenador(a)', label: 'Coordenador(a)' },
  { value: 'Analista', label: 'Analista' },
  { value: 'Técnico(a)', label: 'Técnico(a)' },
  { value: 'Assistente', label: 'Assistente' },
  { value: 'Estagiário(a)', label: 'Estagiário(a)' },
  { value: 'Estudante', label: 'Estudante' }
]

export const NUMBER_OF_SUBSCRIBE_OPTIONS: any[] = [
  {
    id: 'once',
    value: '1',
    label: 'Apenas uma vez',
    isDeleted: false,
    path: ''
  },
  {
    id: 'twice',
    value: '2',
    label: 'Duas vezes',
    isDeleted: false,
    path: ''
  },
  {
    id: 'three',
    value: '3',
    label: 'Três vezes',
    isDeleted: false,
    path: ''
  },
  {
    id: 'four',
    value: '4',
    label: 'Quatro vezes',
    isDeleted: false,
    path: ''
  },
  {
    id: 'five',
    value: '5',
    label: 'Cinco vezes',
    isDeleted: false,
    path: ''
  },
  {
    id: 'six',
    value: '6',
    label: 'Seis vezes',
    isDeleted: false,
    path: ''
  },
  {
    id: 'seven',
    value: '7',
    label: 'Sete vezes',
    isDeleted: false,
    path: ''
  },
  {
    id: 'eight',
    value: '8',
    label: 'Oito vezes',
    isDeleted: false,
    path: ''
  },
  {
    id: 'nine',
    value: '9',
    label: 'Nove vezes',
    isDeleted: false,
    path: ''
  },
  {
    id: 'ten',
    value: '10',
    label: 'Dez vezes',
    isDeleted: false,
    path: ''
  }

]
